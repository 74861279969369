/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    videoLinks: []
};

export const downloadVideosSlice = createSlice({
    initialState,
    name: 'download_videos',
    reducers: {
        checkoutAllCuts: (state) => {
            state.videoCuts = [];
            state.selectedCuts = [];
        },
        setVideoLinks: (state, action) => {
            state.videoLinks = action.payload;
        },
        addVideoLink: (state, action) => {
            state.videoLinks = [...state.videoLinks, action.payload];
        },
        removeVideoLink: (state, action) => {
            const cleanedVideoLinks = [...state.videoLinks].filter(item => item !== action.payload);
            state.videoLinks = cleanedVideoLinks;
        },
    },
});

export const { actions: downloadVideosActions } = downloadVideosSlice;
export const { reducer: downloadVideosReducer } = downloadVideosSlice;

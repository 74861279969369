import React, { useState, useEffect } from "react";
import { breakpoints } from "../../style/size";
import styled from "styled-components";
import searchIcon from "../../images/ic_search.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { $apiV1 } from "../../config/api";
import placeholder from "../../images/ic_logo.svg";

const sizeOptions = {
  medium: {
    width: "221px",
    height: "40px",
  },
  large: {
    width: "423px",
    height: "40px",
  },
  full: {
    width: "100%",
    height: "40px",
  },
};

export const SearchWrapper = styled.div`
  width: -webkit-fill-available;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 13px 10px;

  border-radius: 1px;
  border: 1px solid ${(props) => props.theme.blueish3};
  opacity: 1;

  @media only screen and (${breakpoints.desktop}) {
    width: ${(props) => sizeOptions[props.size].width};
  }

  input {
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    &::placeholder {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiMedium";
      font-size: 16px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
  }

  img {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;

const ResultsDropdown = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.background};
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  

  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 30px;
    cursor: pointer;
    font-family: "SatoshiMedium";
    color: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.blueish3};
    &:hover {
      background: ${(props) => props.theme.blueish3};
    }
  }
`;

const Index = ({ size = "large" }) => {
  const { t } = useTranslation();

  const [text, setText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (text.length >= 1) {
      const fetchResults = async () => {
        try {
          const teamResponse = await $apiV1.get(
            `/platform/team/?search=${text}`
          );
          const playerResponse = await $apiV1.get(
            `/platform/player/?search=${text}`
          );

          const teams = teamResponse.data.results.map((team) => ({
            ...team,
            type: "team",
          }));
          const players = playerResponse.data.results.map((player) => ({
            ...player,
            type: "player",
          }));

          const combinedResults = [...teams, ...players];
          setSearchResults(combinedResults);
        } catch (error) {
          console.error("Error fetching search results:", error);
          setSearchResults([]);
        }
      };

      fetchResults();
    } else {
      setSearchResults([]);
    }
  }, [text]);

  const handleNavigate = (result) => {
    navigate(
      result.type === "team"
        ? `/tournaments/team/${result.id}`
        : `/tournaments/player/${result.id}`
    );
    setSearchResults([]);
  };

  return (
    <>
      <SearchWrapper size={size}>
        <img src={searchIcon} alt="search icon" />
        <input
          type="text"
          placeholder={t("search.title")}
          onChange={(e) => setText(e.target.value)}
          value={text}
        />
        {searchResults.length > 0 && (
          <ResultsDropdown>
            {searchResults.map((result, index) => (
              <div key={index} onClick={() => handleNavigate(result)}>
                {result?.logo ? (
                  <img
                    src={result.logo}
                    alt={result.logo}
                  />
                ) : result?.photos && result.photos.length > 0 ? (
                  <img
                    src={`https://platform.smrtstats.com:8888/${result.photos[0].image}`}
                    alt={result.photos[0].image}
                  />
                ) : (
                  <img src={placeholder} alt={placeholder} />
                )}
                {result.name} {result.surname}
              </div>
            ))}
          </ResultsDropdown>
        )}
      </SearchWrapper>
    </>
  );
};

export default Index;

import React from "react";
import { useNavigate } from "react-router-dom";

// Style
import {
  FooterWrapper,
  FooterLeftSide,
  FooterRightSide,
  Content,
} from "../../style/LandingPage";

// Images
import logo from "../../images/footerLogo.svg";

// Components
import Button from "../../components/button";

// Language
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLink = () => {
    navigate("/register");
  };

  return (
    <FooterWrapper>
      <FooterLeftSide>
        <img src={logo} alt={logo} />
        <h4>{t("footer.desc")}</h4>
        <Button
          title={t("footer.button")}
          color={"secondary"}
          onClick={() => handleLink()}
        />
      </FooterLeftSide>
      <FooterRightSide>
        <Content>
          <h5>{t("footer.mainLink")}</h5>
          <ul>
            <li>
              <a href="/#platform">{t("footer.platform")}</a>
            </li>
            <li>
              <a href="/#services">{t("footer.services")}</a>
            </li>
            <li>
              <a href="/#about">{t("footer.aboutUs")}</a>
            </li>
            <li>
              <a href="/#contact">{t("footer.contactUs")}</a>
            </li>
          </ul>
        </Content>
        <Content>
          <h5>{t("footer.otherLinks")}</h5>
          <ul>
            <li>
              <a href="/terms">{t("footer.privacyPolicy")}</a>
            </li>
            <li>
              <a href="/terms">{t("footer.termsConditions")}</a>
            </li>
          </ul>
        </Content>
        <Content>
          <h5>{t("footer.contactDetails")}</h5>
          <ul>
            <li>
              <a href="mailto:mail@support.com">sales@smrtstats.com</a>
            </li>
            <li>
              <a>
                13 Honeypark, Dun Laoghaire, <br />Dublin, Ireland
              </a>
            </li>
          </ul>
        </Content>
      </FooterRightSide>
    </FooterWrapper>
  );
};

export default Footer;

import React, { useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  // useSearchParams
} from "react-router-dom";

// Landing page
import Landing from "./pages/landing/Main";

// Auth pages
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import SuccessSend from "./pages/auth/SuccessSend";
import PasswordReset from "./pages/auth/PasswordReset";

// Dashboard pages
import Dashboard from "./pages/dashboard/Index";

import Tournaments from "./pages/tournametns/Index";
import Team from "./pages/tournametns/team/Index";
import Player from "./pages/tournametns/player/Index";
import Statistics from "./pages/tournametns/statistics/Index";
import Formation from "./pages/tournametns/formation/Index";
import Competition from "./pages/tournametns/competition/Index";

import SmrtSearch from "./pages/smrtSearch/index";
import Compare from "./pages/compare/Index";
import SmrtPlayer from "./pages/smrtSearch/Id";
import SmrtCurrentClub from "./pages/smrtSearch/CurrentClub";
import SmrtCompare from "./pages/smrtSearch/Compare";
import Favorites from "./pages/favorites/Index";
import Playlist from "./pages/playlist/Index";
import SharedPlaylist from "./pages/shared_playlist/Index";
import Setup from "./pages/setup/Index";
import Account from "./pages/account/Index";
import Contact from "./pages/contact/Index";
import UserPlaylist from "./pages/user_playlist/index";

import { BetaVersion } from "./style/SmrtSearch";
import version from "./images/version.png";
import versionBlack from "./images/versionBlack.png";
import closeIcon from "./images/ic_close_white.svg";
import closeIconBlack from "./images/ic_close_black.svg";

// import { SystemSettings } from "../SystemSettings";
// import { SystemSettings } from "./components/SystemSettings";
import {
  $apiV1,
  // isProduction,
  //  TEST_HOST
} from "./config/api";

// 404
import Error from "./pages/error/Index";

// Privacy pages
import Terms from "./pages/terms/index";
import Privacy from "./pages/privacy/index";
import Video from "./pages/tournametns/player/Video";
// import { VideoList } from "./style/Overview";
import VideoListPage from "./pages/tournametns/player/VideoList";
import { ProtectedRoute } from "./components/ProtectedRoute";
import {
  useCallback,
  useEffect,
  //  useState
} from "react";
import { useDispatch } from "react-redux";
import { userActions } from "./store/user/userSlice";
import { VideoListUpdated } from "./pages/tournametns/player/VideoListUpdated";
import { DowloadVideoList } from "./components/downLoadVideos/DowloadVideoList";
import { getLanguages, getTranslates } from "./store/languages/languagesService";
import { LanguageSwitcher } from "./components/LanguageSwitcher/LanguageSwitcher";
import { getFormationInfo } from "./store/user/userService";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  // const [routePath, setRoutePath] = useState(location.pathname);
  // const [searchParams] = useSearchParams();

  const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem('showBetaInfo')) ?? true);

  const handleClick = () => {
    setExpanded(!expanded);
    localStorage.setItem('showBetaInfo', JSON.stringify(!expanded));
  };

  const checkUserToken = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token || token === "undefined") {
      dispatch(userActions.setToken(""));
      return;
    }
    dispatch(userActions.setToken(token));
  }, [dispatch]);

  useEffect(() => {
    checkUserToken();
  }, [checkUserToken]);

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getTranslates(localStorage.getItem('language') || 'en'));
    dispatch(getFormationInfo());
  }, [dispatch]);

  useEffect(() => {
    if (!location.pathname.includes("/login")) {
      $apiV1.get("/users/check_token/").catch(() => {
        localStorage.removeItem("token");
        checkUserToken();
      });
    }
    //code for send activity user to server
    // const path = searchParams.size ? `?${decodeURI(searchParams.toString())}` : '';
    // $apiV1.post('/users/activity/', {
    //   link_from: `https://football.smrtstats.com${routePath}${path}`,
    //   link_to: `https://football.smrtstats.com/${location.pathname}${path}`,
    //   type: 1,
    // }).finally(() => {
    //   setRoutePath(location.pathname)
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <DowloadVideoList />
      {/* <LanguageSwitcher /> */}
      <Routes>
        <Route path="/" element={<Landing />}></Route>

        {/* Privacy pages */}
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>

        {/* Auth pages */}
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/success" element={<SuccessSend />}></Route>
        <Route path="/password-reset" element={<PasswordReset />}></Route>
        {/* Dashboard pages */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/tournaments"
          element={
            <ProtectedRoute>
              <Tournaments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tournaments/team/:id"
          element={
            <ProtectedRoute>
              <Team />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tournaments/player/:id"
          element={
            <ProtectedRoute>
              <Player />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tournaments/match/video/:id"
          element={
            <ProtectedRoute>
              <Video />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tournaments/competition/:id"
          element={
            <ProtectedRoute>
              <Competition />
            </ProtectedRoute>
          }
        />

        <Route
          path="/tournaments/match/video"
          element={
            <ProtectedRoute>
              {/* <VideoListPage /> */}
              <VideoListUpdated />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tournaments/statistics/:id"
          element={
            <ProtectedRoute>
              <Statistics />
            </ProtectedRoute>
          }
        />

        <Route
          path="/tournaments/formation"
          element={
            <ProtectedRoute>
              <Formation />
            </ProtectedRoute>
          }
        />

        <Route
          path="/smrt-search"
          element={
            <ProtectedRoute>
              <SmrtSearch />
            </ProtectedRoute>
          }
        />

        <Route
          path="/compare"
          element={
            <ProtectedRoute>
              <Compare />
            </ProtectedRoute>
          }
        />
        <Route
          path="/smrt-search/:id"
          element={
            <ProtectedRoute>
              <SmrtPlayer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/smrt-search/compare"
          element={
            <ProtectedRoute>
              <SmrtCompare />
            </ProtectedRoute>
          }
        />
        <Route
          path="/smrt-search/current-club"
          element={
            <ProtectedRoute>
              <SmrtCurrentClub />
            </ProtectedRoute>
          }
        />
        <Route
          path="/favorites"
          element={
            <ProtectedRoute>
              <Favorites />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-playlists"
          element={
            <ProtectedRoute>
              <Playlist />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-playlists/:id"
          element={
            <ProtectedRoute>
              <UserPlaylist />
            </ProtectedRoute>
          }
        />
        <Route path="/shared_playlist/:id" element={<SharedPlaylist />} />
        <Route
          path="/platform-setup"
          element={
            <ProtectedRoute>
              <Setup />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact-us"
          element={
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          }
        />

        {/* 404 */}
        <Route path="/error" element={<Error />}></Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <BetaVersion onClick={handleClick}>
        {!expanded &&
          <img
            src={
              localStorage.getItem("theme") === "light" ? version : versionBlack
            }
            alt={version}
          />
        }
        {expanded && (
          <span>
            <img
              src={
                localStorage.getItem("theme") === "light"
                  ? closeIcon
                  : closeIconBlack
              }
              alt={closeIcon}
            />
            This is a beta version. We would be grateful if you contribute and
            help us make it better.
          </span>
        )}
      </BetaVersion>
    </>
  );
}

export default App;

import styled, { css } from "styled-components";
import "../../style/Global";

import { breakpoints } from "../../style/size";

export const IntroTextWrap = styled.div`
  padding-top: 60px;

  @media only screen and (${breakpoints.mobile}) {
    padding-top: 20px;
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.green};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  h1 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 50px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 32px;
      line-height: 46px;
    }
  }
`;

const Index = ({ smallTitle, title, style }) => {
  return (
    <IntroTextWrap>
      {/* <p>{smallTitle}, </p> */}
      <h1 style={style}>{title}</h1>
    </IntroTextWrap>
  );
};

export default Index;

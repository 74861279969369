import styled from "styled-components";

export const IconWrapper = styled.div`
  cursor: pointer;
  line-height: 0;


  svg {
    fill: ${props => props.theme.white};
    stroke: ${props => props.theme.white};
    
    g {
        fill: ${props => props.theme.white};
    }

    #Path {
        path {
            fill: ${props => props.theme.white};
        }
    }
  }
`
import React, { useState, useEffect } from 'react';
import { downloadVideosActions } from '../../../../store/download_videos/downloadVideosSlice';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useNotification } from '../../../../components/notification/useNotification';
import { NotificationList } from '../../../../components/notification/NotificationList';

export const VideoLoaderWrap = styled.div`
  border: 1px solid rgba(24, 31, 4, 1);
  box-shadow: 0px 3px 15px ${(props) => props.theme.green};
  opacity: 1;
  background-color: ${(props) => props.theme.black};
`

const VideoDownloader = React.memo(({ videoUrl }) => {
    const dispatch = useDispatch();
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [abortController, setAbortController] = useState(null);
    const [filename, setFileName] = useState(`${Date.now()}.mp4`);

    const { notifications, addNotification, removeNotification } = useNotification();

    useEffect(() => {
        const splittedUrl = videoUrl?.split?.('/');

        if (splittedUrl && splittedUrl?.length > 0) {
            setFileName(splittedUrl?.[splittedUrl?.length - 1]);
        }
    }, [videoUrl])

    useEffect(() => {
        if (videoUrl && filename) {
            handleDownload();
        }
    }, [videoUrl, filename])

    const handleDownload = async () => {
        if (isDownloading) return;

        setIsDownloading(true);
        setIsCancelled(false);

        const controller = new AbortController();
        setAbortController(controller);

        try {
            const response = await fetch(videoUrl, { signal: controller.signal });
            if (!response.ok) {
                throw new Error('Ошибка загрузки видео');
            }

            const totalSize = response.headers.get('Content-Length');
            const reader = response.body.getReader();

            const chunks = [];
            let loaded = 0;

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                chunks.push(value);
                loaded += value.length;

                const progress = (loaded / totalSize) * 100;
                setDownloadProgress(progress);

                if (isCancelled) {
                    controller.abort();
                    break;
                }
            }

            if (!isCancelled) {
                const blob = new Blob(chunks, { type: 'video/mp4' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                URL.revokeObjectURL(link.href);
                dispatch(downloadVideosActions.removeVideoLink(videoUrl))
            }

            setIsDownloading(false);
        } catch (error) {
            if (error.name === 'AbortError') {
                addNotification(`Download ${filename} canceled`, 'warning');
            } else {
                addNotification(`Video ${filename} download error: ${error.message}`, 'error');
            }
            setIsDownloading(false);
        }
    };

    const handleCancel = () => {
        setIsCancelled(true);
        if (abortController) {
            abortController.abort();
        }
        dispatch(downloadVideosActions.removeVideoLink(videoUrl))
    };

    useEffect(() => {
        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [abortController]);

    return (
        <>
            <div>
                <p>
                    {decodeURI(filename)}
                </p>
                <div className='progressBarContainer'>
                    <div
                        className='progressBarDone'
                        style={{
                            width: `${downloadProgress.toFixed(2)}%`,
                        }}
                    >
                        {downloadProgress.toFixed(2)}%
                    </div>
                </div>
                <div className='progressCancelBtn' onClick={handleCancel}>Cancel download</div>
            </div>
            {/* display notifications */}
            <NotificationList
                notifications={notifications}
                onClose={removeNotification}
            />
        </>

    );
});

export default React.memo(VideoDownloader);
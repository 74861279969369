import styled from "styled-components";
import { breakpoints } from "./size";

export const BestPlayerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 50px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const BestPlayerCard = styled.div``;

export const BestPlayersTable = styled.div`
  width: 45%;

  button {
    background: transparent;
    color: ${(props) => props.theme.green};
    text-decoration: underline;
    border: none;
    font-family: "SatoshiMedium";
    cursor: pointer;
  }

  &.teamCardSize {
    width: 30%;

    @media only screen and (${breakpoints.mobile}) {
      width: 100%;
    }
  }

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    // padding-left: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
`;

export const BestPlayerContent = styled.div`
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.linesOpacity};
  padding: 10px 24px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;

  &.bestTeamContent {
    padding: 10px;
  }

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    align-items: baseline;
    gap: 10px;

    &.bestTeamContent {
      flex-direction: row;
    }
  }

  &:nth-child(odd) {
    background: ${(props) => props.theme.lines};
  }
`;

export const BestPlayerName = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  text-decoration: none;

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  .profileImage {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
`;

export const BestPlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BestPlayerClub = styled.div`
  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const BestPlayerValue = styled.div`
  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const TeamsTableWrap = styled.div``;

export const TopTableLabel = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;

  h5 {
    opacity: 1;
    color: rgba(115, 128, 137, 1);
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 18px;

    &:nth-child(1) {
      width: 5%;
    }

    &:nth-child(2) {
      width: 50%;
    }

    &:nth-child(3) {
      width: 5%;
    }

    &:nth-child(4) {
      width: 5%;
    }

    &:nth-child(5) {
      width: 5%;
    }

    &:nth-child(6) {
      width: 5%;
    }

    &:nth-child(7) {
      width: 10%;
    }

    &:nth-child(8) {
      width: 20%;
    }

    &:nth-child(9) {
      width: 5%;
    }
  }
`;

export const BottomTableStats = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  margin-top: 6px;
`;

export const BottomContent = styled.div`
  padding: 15px 10px;
  display: flex;
  align-item: center;

  &:nth-child(even) {
    background: ${(props) => props.theme.black};
  }
`;

export const Rang = styled.div`
  width: 5%;

  h5 {
    width: 28px;
    height: 28px;
    background: #36e82d30;
    opacity: 1;
    color: rgba(54, 232, 45, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const Team = styled.div`
  width: calc(48% - 15px);

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;
export const ShortP = styled.div`
  width: calc(5% - 2px);

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;
export const Wins = styled.div`
  width: calc(5% - 4px);

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;
export const Draw = styled.div`
  width: calc(5% - 4px);

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;
export const Lose = styled.div`
  width: 5%;

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;
export const Goals = styled.div`
  width: calc(8% + 10px);

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;
export const LastFive = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(20% - 10px);
`;

export const Square = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
  font-family: "SatoshiBold";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: center;
  line-height: 24px;
  border-radius: 2px 0px 0px 2px;
  opacity: 1;

  &.win {
    background-color: #36e82d30;
    color: rgba(54, 232, 45, 1);
  }

  &.lose {
    background-color: rgba(242, 10, 70, 0.3);
    color: rgba(242, 10, 70, 1);
  }

  &.draw {
    background-color: rgba(232, 208, 45, 0.3);
    color: rgba(232, 208, 45, 1);
  }
`;

export const Points = styled.div`
  width: calc(3% + 5px);
  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

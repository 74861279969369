import React from "react";
import styled from "styled-components";

// Styled components
const RadioButtonAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  height: 44px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;

  label {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding-left: 5px;
    display: flex;
    align-items: center;
  }

  /* Checked label text color */
  input:checked + label {
    opacity: 1;
    font-family: "SatoshiBold";
    color: ${(props) => props.theme.white};
  }
`;
const RadioButtonInput = styled.input`
  /* Hide default radio button */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;

  /* Style custom radio button */
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.theme.white};
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  /* Checked style */
  &:checked {
    border-color: ${(props) => props.theme.green};
  }

  /* Dot inside the radio button */
  &:checked::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.green};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

// RadioButton component
const RadioButton = ({ label, value, checked, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <RadioButtonAreaWrapper>
      <RadioButtonInput
        type="radio"
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <label>{label}</label>
    </RadioButtonAreaWrapper>
  );
};

export default RadioButton;

import styled from "styled-components";
import { breakpoints } from "./size";

export const PlayerCard = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
  margin-bottom: 60px;

  @media only screen and (${breakpoints.mobile}) {
    margin-bottom: 40px;
  }
`;

export const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const TopInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.lines};

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const LeftInfo = styled.div`
  display: flex;
  gap: 16px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Profile = styled.div`
  position: relative;
  width: fit-content;

  .profile {
    width: 80px;
    height: 80px;

    @media only screen and (${breakpoints.mobile}) {
      width: 44px;
      height: 44px;
    }
  }

  .flag {
    width: 25px;
    height: 25px;
    right: -10px;
    top: -10px;
    position: absolute;

    @media only screen and (${breakpoints.mobile}) {
      width: 15px;
      height: 15px;
      top: -7px;
      right: -7px;
    }
  }
`;

export const PlayerTitle = styled.div`
  display: flex;
  flex-direction: column;

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 28px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 38px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 24px;
      line-height: 34px;
    }
  }
`;

export const RightInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media only screen and (${breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const BottomInfo = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (${breakpoints.mobile}) {
    flex-wrap: wrap;
    justify-content: unset;
    row-gap: 20px;
  }
`;

export const PlayerClubInfo = styled.div`
  @media only screen and (${breakpoints.mobile}) {
    width: 50%;
  }

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }

  h5 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    TopWrapperVideo,
    TopWrapperVideoContent,
    VideoContent,
    VideoPlayerWrapper,
    VideoWrapper
} from '../../../style/VideoStyle';
import Button from '../../../components/button';
import Navigation from '../../../components/Navigation/Navigation';
import { FormWrapPopup, MainWrapper, TopWrapper } from '../../../style/DasboardMain';
import { useSearchParams } from 'react-router-dom';
import { $apiV1, VIDEO_SERVICE_URL } from '../../../config/api';
import { getVideoName, groupEventsByTeam } from './helpers';
import { EventsPanel } from './ui/EventsPanel';
import { VideoPlayer } from './ui/VideoPlayer';
import Modal from "../../../components/modal/index";
import TextInput from '../../../components/inputs/TextInput';
import { SelectDropdown } from '../../../style/Global';
import IntroText from "../../../components/introText/index";
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from './ShotsPlayer';

import arrowIconUp from "../../../images/up.svg";
import arrowIconDown from "../../../images/down.svg";
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

export const VideoListUpdated = () => {
    const [searchParams] = useSearchParams();
    const title = searchParams.get('title');
    const { offset_range_begin = 10, offset_range_end = 10 } = useSelector(state => state.user.userProfile);

    const [countEpisode, setCountEpisode] = useState(0);
    const [countMatches, setCountMatches] = useState(0);
    const [videoCutsServer, setVideoCutsServer] = useState([]);
    const [videoCuts, setVideoCuts] = useState([]);
    const [videoCutsForHandleVideo, setVideoCutsForHandleVideo] = useState([]);
    const [videoHref, setVideoHref] = useState('');
    const [videoSecond] = useState(0);

    const [loadingVideo, setLoadingVideo] = useState(false);

    const [openAddVideo, setOpenAddVide] = useState(false);
    const [folderName, setFolderName] = useState("");

    const [userPlaylists, setUserPlaylists] = useState([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loadingAddPlaylist, setLoadingAddPlaylist] = useState(false);

    const [record, setRecord] = useState(null);
    const [recordTime, setRecordTime] = useState(null);

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const currentVideoIndexRef = useRef(0);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [disabledNext, setDisabledNext] = useState(true);
    const [disabledPrev, setDisabledPrev] = useState(true);

    const [loadingDownloadCuts, setLoadingDownloadCuts] = useState(false);

    const [loadingMarkers, setLoadingMarkers] = useState(true);

    // const currentVideoIndexOnCommonList = useMemo(() => {
    //     if (currentVideo?.id && videoCutsForHandleVideo?.length > 0) {
    //         const idx = videoCutsForHandleVideo.findIndex(item => item.id === currentVideo.id);
    //         if (idx >= 0) {
    //             return idx;
    //         }
    //         return 0
    //     }
    //     return 0
    // }, [currentVideo, videoCutsForHandleVideo])

    const filteredVideoSources = useMemo(() => {
        if (videoCutsForHandleVideo?.length > 0) {
            return videoCutsForHandleVideo.filter(item => Boolean(item.video));
        }

        return [];
    }, [videoCutsForHandleVideo]);

    const selectedEvents = useMemo(() => {
        if (videoCuts.length > 0) {
            return videoCuts.filter(item => !item.braker && item.checked);
        }
        return [];
    }, [videoCuts]);

    useEffect(() => {
        const getUserPlaylists = async () => {
            try {
                const res = await $apiV1.get('/users/playlists/');
                setUserPlaylists(res.data);
            } catch (e) {
                console.log('get user playlists err', e.message);
            }
        }

        getUserPlaylists();
    }, [])

    useEffect(() => {
        const markers = searchParams.get('markers');

        const getMatchMarkers = async (events) => {
            setLoadingMarkers(true);
            try {
                const response = await $apiV1.post('/stats/events_search/', { markers: events.split(',') })
                const data = await response.data;
                if (data) {
                    const groupedData = groupEventsByTeam(response.data.events)
                    setCountEpisode(data.count);
                    setCountMatches(groupedData.length);
                    setVideoCutsServer(groupedData);
                }
            } catch (e) {
                console.log('get video cuts err', e.message);
            } finally {
                setLoadingMarkers(false);
            }
        }

        if (markers) {
            getMatchMarkers(markers);
        }
    }, [searchParams]);

    const debouncedPlayVideo = useCallback(debounce(handlePlayVideo, 300), [])

    useEffect(() => {
        const markers = getPrepearedMarkers(videoCutsServer)
        setVideoCuts(markers);
        let videoSource = videoCutsForHandleVideo?.[currentVideoIndex] ?? currentVideo;
        if (videoSource) {
            debouncedPlayVideo(videoSource.video, videoSource.second, videoSource, null, offset_range_begin, offset_range_end);
        }
        console.log(videoSource)
    }, [videoCutsServer, offset_range_begin, offset_range_end]);

    useEffect(() => {
        const markers = getPrepearedMarkers(videoCutsServer)
        setVideoCutsForHandleVideo(markers);
    }, [videoCutsServer])

    useEffect(() => {
        let videoSource = videoCutsForHandleVideo[currentVideoIndex];
        if (videoSource?.braker) {
            setCurrentVideoIndex(currentVideoIndex + 1);
            return
        }
        if (!videoSource) return;
        setDisabledPrev(currentVideoIndex === 0);
        setDisabledNext(currentVideoIndex >= videoCutsForHandleVideo.length - 1);
        handlePlayVideo(videoSource.video, videoSource.second, videoSource);
    }, [currentVideoIndex, videoCutsForHandleVideo]);

    function getPrepearedMarkers(videos) {
        if (!videos?.length) return []

        const allMarkers = videos?.reduce((res, videoCut) => {
            if (videoCut?.markers?.length > 0) {
                res.push({
                    braker: true,
                    key: Math.random(),
                    markers: videoCut.markers,
                    match: videoCut.match,
                });
                const tmp = [...videoCut.markers];
                res.push(...tmp.sort((a, b) => {
                    if (a?.second && b?.second && +a.second > +b.second) {
                        return 1;
                    }
                    if (a?.second && b?.second && +a.second < +b.second) {
                        return -1;
                    }
                    return 0;
                }));
            }
            return res;
        }, []);
        const preparedMarkers = allMarkers
            ?.map((item) => {
                if (item?.braker) {
                    return { ...item, checked: false }
                }
                return {
                    ...item,
                    checked: false,
                    title: getVideoName(item, { start: offset_range_begin, end: offset_range_end }) ?? '',
                }
            })

        return preparedMarkers;
    }

    async function handlePlayVideo(
        link,
        second,
        video,
        idx,
        range_begin,
        range_end
    ) {
        try {
            if (idx) {
                setCurrentVideoIndex(idx);
                return;
            }
            setLoadingVideo(true);

            setCurrentVideo(video);
            setRecord(video);

            let currentOffSets = { start: range_begin ?? offset_range_begin, end: range_end ?? offset_range_end }

            // set offset
            if (+video?.second >= 2700 && (currentOffSets.start === 10 && currentOffSets.end === 10)) {
                currentOffSets = { start: 10, end: 25 };
            }

            if (link) {
                const url = `${VIDEO_SERVICE_URL}/cut_video/`;
                const response = await fetch(url, {
                    body: JSON.stringify({
                        offset_range_begin: currentOffSets.start,
                        offset_range_end: currentOffSets.end,
                        path: decodeURI(link),
                        start: Math.round(second),
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                });
                const json = await response.json();

                if (json.status === 'ok') {
                    setVideoHref(`${VIDEO_SERVICE_URL}/video/${json.out_file}`);
                    // const path = searchParams.size ? `?${decodeURI(searchParams.toString())}` : '';
                    // $apiV1.post('/users/activity/', {
                    //     entity_id: id,
                    //     link_from: `https://platform.smrtstats.com${location.pathname}${path}`,
                    //     link_to: `${VIDEO_SERVICE_URL}/video/${json.out_file}`,
                    //     type: 3,
                    // })
                } else if (json.status === 'error') {
                    alert(json.input_path || json.start || json.out_file);
                } else {
                    alert('Get video error');
                }
            }
        } catch (e) {
            alert(`Get video error, ${e.message}`);
            console.log('get cut video err', e.message);
        } finally {
            setLoadingVideo(false);
        }
    };

    const toggleCheckCut = (checked, event) => {
        let cuts = [...videoCuts];

        const cutIndex = cuts.findIndex((tab) => tab.id === event.id);
        if (cutIndex >= 0) {
            if (cutIndex >= 0) {
                cuts[cutIndex].checked = checked;
            }
        }
        setVideoCuts(cuts);
    }

    const toggleAllEventsOnMatch = (checked, events) => {
        events?.forEach((event) => {
            if (event.video) {
                toggleCheckCut(checked, event);
            }
        })
    }

    const handleEmailChange = (newValue) => {
        setFolderName(newValue);
    };

    // const getCutVideo = async (params) => {
    //     const result = await $apiV1.post(
    //         `${VIDEO_SERVICE_URL}/cut_video/`,
    //         params,
    //     );

    //     return result
    // }

    const addNewFolder = async () => {

        try {
            setLoadingAddPlaylist(true);
            let playlist;

            if (!selectedPlaylist) {
                // First API request to create a new folder
                const createFolderResponse = await $apiV1.post(`/users/playlists/`,
                    { title: folderName },
                );
                playlist = createFolderResponse.data;
                setUserPlaylists(prev => [...prev, playlist])
            } else {
                playlist = selectedPlaylist;
            }

            setOpenAddVide(!openAddVideo);

            if (record?.id && recordTime?.startTime && recordTime?.endTime) {
                const params = {
                    end_second: +(+recordTime.endTime).toFixed(0),
                    start_second: +(+recordTime.startTime).toFixed(),
                    event_id: record.id,
                    title: `Record from ${recordTime.startTime.toFixed(0)} to ${recordTime.endTime.toFixed(0)}`
                }
                // await getCutVideo(params);
                await $apiV1.post(`/users/playlists/${playlist.id}/add/`,
                    [params],
                );
                return
            }

            // Ensure selectedCheckboxes is an array of event_ids
            const events = selectedEvents?.length > 0
                ? selectedEvents.map((event) => ({
                    event_id: event.id,
                    title: event.title,
                }))
                : [];

            if (events?.length !== 0) {
                // Second API request to add events to the newly created folder
                await $apiV1.post(`/users/playlists/${playlist.id}/add/`,
                    events,
                );
                videoCuts?.map(item => toggleCheckCut(false, item));
            }

            // You can handle the response of the second API request here if needed
            // setCheck(addEventsResponse.data);
            // setOpenAddVide(!openAddVideo);
        } catch (error) {
            console.error("Error processing request:", error);
        } finally {
            setLoadingAddPlaylist(false);
        }
    };

    const handleSelectChange = (value) => {
        setSelectedPlaylist(value);
    };

    const addRecordToPlaylist = () => {
        setOpenAddVide(true);
    }

    const handlePrevVideo = () => {
        if (currentVideoIndex > 0) {
            let idx = currentVideoIndex - 1
            let videoSource = videoCutsForHandleVideo[idx];
            if (videoSource?.braker) {
                setCurrentVideoIndex(idx - 1);
                return
            }
            setCurrentVideoIndex(idx);
        }
    };

    const handleNextVideo = () => {
        if (currentVideoIndex < videoCutsForHandleVideo.length - 1) {
            let idx = currentVideoIndex + 1
            let videoSource = videoCutsForHandleVideo[idx];
            if (videoSource?.braker) {
                setCurrentVideoIndex(idx + 1);
                return
            }
            setCurrentVideoIndex(idx);

        }
    };


    const prepeareCutsForServer = (data) => {
        // eslint-disable-next-line consistent-return,array-callback-return
        const cuts = Object.entries(data).map(([key, values]) => {
            const path = decodeURI(values.video);
            return {
                end: Math.round(+values.second) + offset_range_end,
                path,
                start: Math.round(+values.second) - offset_range_begin,
            };
        });
        return cuts;
    };

    const handleDownloadSelectedCuts = async () => {
        setLoadingDownloadCuts(true);
        try {
            let data = [];
            if (selectedEvents?.length === 0 && videoCuts?.length > 0) {
                data = videoCuts?.filter(item => !Boolean(item.braker))
            } else {
                data = selectedEvents;
            }
            const cuts = prepeareCutsForServer(
                data.filter((item) => Boolean(item.video)),
            );
            if (cuts.length > 0) {
                const url = `${VIDEO_SERVICE_URL}/cut_many_video/?out=zip`;
                const response = await fetch(url, {
                    body: JSON.stringify(cuts),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                });
                const json = await response.json();

                if (json.status === 'ok') {
                    const a = document.createElement('a');
                    a.href = `${VIDEO_SERVICE_URL}/video/${json.out_file}`;
                    a.download = json.out_file;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } else if (json.status === 'error') {
                    alert(
                        json.input_path ||
                        json.seconds ||
                        json.out_file ||
                        json.content_type,
                    );
                } else {
                    alert('Get zip archive error');
                }
            } else {
                alert('There are no videos available for download');
            }
        } catch (e) {
            alert(`Get zip archive error, ${e.message}`);
        } finally {
            setLoadingDownloadCuts(false);
        }
    };

    const handleDownloadSelectedCutsInOneClip = async () => {
        setLoadingDownloadCuts(true);
        try {
            let data = [];
            if (selectedEvents?.length === 0 && videoCuts?.length > 0) {
                data = videoCuts?.filter(item => !Boolean(item.braker))
            } else {
                data = selectedEvents;
            }
            const cuts = prepeareCutsForServer(
                data.filter((item) => Boolean(item.video)),
            );
            if (cuts.length > 0) {
                const url = `${VIDEO_SERVICE_URL}/cut_many_video/?out=file`;
                const response = await fetch(url, {
                    body: JSON.stringify(cuts),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                });
                const json = await response.json();

                if (json.status === 'ok') {
                    const a = document.createElement('a');
                    a.href = `${VIDEO_SERVICE_URL}/video/${json.out_file}`;
                    a.download = json.out_file;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } else if (json.status === 'error') {
                    alert(
                        json.input_path ||
                        json.seconds ||
                        json.out_file ||
                        json.content_type,
                    );
                } else {
                    alert('Get zip archive error');
                }
            } else {
                alert('There are no videos available for download');
            }
        } catch (e) {
            alert(`Get zip archive error, ${e.message}`);
        } finally {
            setLoadingDownloadCuts(false);
        }
    };

    const modal = (
        <Modal isOpen={openAddVideo} setIsOpen={setOpenAddVide} closeIc>
            <h1>
                {
                    record && recordTime?.startTime && recordTime?.endTime
                        ? 'Add record to Playlist'
                        : `Add ${selectedEvents?.length} events to Playlist `
                }
            </h1>
            <FormWrapPopup style={{ marginTop: "20px" }}>
                <Dropdown>
                    <DropdownButton
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        {!selectedPlaylist
                            ? "Select playlist"
                            : `${selectedPlaylist?.title}`}
                        <img src={showDropdown ? arrowIconUp : arrowIconDown} />
                    </DropdownButton>
                    <DropdownContent show={showDropdown}>
                        {userPlaylists.map((item) => (
                            <DropdownItem
                                key={item.id}
                                selected={item.id === selectedPlaylist?.id}
                                onClick={() => handleSelectChange(item)}
                            >
                                {item.title}
                            </DropdownItem>
                        ))}
                    </DropdownContent>
                </Dropdown>
                <TextInput
                    label="Create New Playlist"
                    type={"text"}
                    value={folderName}
                    placeholder="Write Name"
                    onChange={handleEmailChange}
                />
            </FormWrapPopup>
            <Button
                style={{ zIndex: 1 }}
                title="Add to playlist"
                size={"full"}
                onClick={() => addNewFolder()}
                disabled={loadingAddPlaylist}
            />
        </Modal>
    );

    return (
        <MainWrapper>
            <Navigation />
            <VideoWrapper className={
                localStorage.getItem("navigationOpen") === "true"
                    ? ""
                    : "fullWidthPage"
            }>
                <TopWrapper>
                    <IntroText title={title} />
                </TopWrapper>
                <VideoPlayerWrapper>
                    <EventsPanel
                        countEpisodes={countEpisode}
                        countMathes={countMatches}
                        videoCuts={videoCuts}
                        handlePlayVideo={handlePlayVideo}
                        toggleCheckCut={toggleCheckCut}
                        toggleAllEventsOnMatch={toggleAllEventsOnMatch}
                        selectedEventsCount={selectedEvents?.length || 0}
                        onOpenAddPlaylistModal={(bool) => {
                            setRecord(null);
                            setOpenAddVide(bool)
                        }}
                        currentVideoIndex={currentVideoIndex}
                        loadingDownloadCuts={loadingDownloadCuts}
                        handleDownloadSelectedCuts={handleDownloadSelectedCuts}
                        handleDownloadSelectedCutsInOneClip={handleDownloadSelectedCutsInOneClip}
                        loadingMarkers={loadingMarkers}
                    />

                    <VideoContent>
                        <VideoPlayer
                            styleWrapper={{ width: '95%' }}
                            video={videoHref}
                            toSecond={videoSecond}
                            playNextVideo={handleNextVideo}
                            playPrevVideo={handlePrevVideo}
                            videos={videoCuts}
                            loading={loadingVideo}
                            record={record}
                            setRecord={setRecord}
                            recordTime={recordTime}
                            setRecordTime={setRecordTime}
                            addRecordToPlaylist={addRecordToPlaylist}
                            disabledNext={disabledNext}
                            disabledPrev={disabledPrev}
                        />
                    </VideoContent>
                </VideoPlayerWrapper>
            </VideoWrapper>
            {modal}
        </MainWrapper>
    )
}

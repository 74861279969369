import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import backgroundImage from "../../../images/ic_filed.png";
import html2canvas from "html2canvas";
import { breakpoints } from "../../../style/size";
import {
  EventMatchFilter,
  EventsMatchesWrap,
  FootballField,
  ListOfMatches,
  ParamsList,
} from "../../../style/EventsMatchesStyle";
import {
  DropdownWrapper,
  OpenedDropdown,
} from "../../../components/table/MatchTable";
import TextInput from "../../../components/inputs/TextInput";

import arrowIconUp from "../../../images/up.svg";
import arrowIconDown from "../../../images/down.svg";
import {
  TabContent,
  TabListWrapper,
  TabsWrapper,
  TabTitle,
  TopList,
} from "../../../style/Favorites";
import Button from "../../../components/button";
import { LoadingState } from "../../../style/Overview";
import { Oval } from "react-loader-spinner";
import { $apiV1 } from "../../../config/api";
import {
  findMaxSecondMarker,
  getUniqueArrayObjectsByKey,
} from "../../../helpers";
import { TimelineChange } from "../../../components/Events/TimelineChange";
import debounce from "lodash.debounce";
import { getCustomZones, getZones } from "../player/helpers";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../store/user/userSlice";

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? '0.6' : '1'};
`;

export const DropdownButton = styled.div`
  background: transparent;
  border: none;
  border-bottom: 1px solid
    ${(props) =>
    props.error
      ? (props) => props.theme.red
      : (props) => props.theme.blueish2};
  outline: none;
  opacity: 1;
  color: ${(props) => props.theme.blueish};
  font-family: "SatoshiRegular";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  padding-left: 5px;
  padding-bottom: 4px;

  &:placeholder {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiRegular";
      font-size: 16px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }

    &:disabled {
      color: ${(props) => props.theme.blueish2}; !important;
    }

    img{
      position: absolute;
      right: 0;
      bottom: 5px;
      cursor: pointer;
    }

`;

export const DropdownContent = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  z-index: 999;
  width: 100%;

  border-radius: 2px;
  opacity: 1;
  background: ${(props) => props.theme.black};
  max-height: 200px;
  overflow: scroll;
  border: 1px solid ${(props) => props.theme.borderColor};

  > div {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding: 10px 6px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.black};
    }
  }
`;

export const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected
      ? (props) => props.theme.green
      : (props) => props.theme.lines};
  color: ${(props) =>
    props.selected
      ? (props) => props.theme.black
      : (props) => props.theme.white} !important;

  &:hover {
    background-color: #ddd;
    color: ${(props) => props.theme.white} !important;
  }
`;

const InfoBox = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.blueish};
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  display: ${(props) => (props.visible ? "block" : "none")};
  top: ${(props) => props.top}px;
  // left: ${(props) => props.left}px;
  // transform: translate(-50%, -100%);
  z-index: 999;
  text-align: center;

  img {
    width: 65px;
    height: 50px;
    object-fit: contain;
    opacity: 1;
  }

  h4 {
    color: ${(props) => props.theme.black};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }

  h5 {
    color: ${(props) => props.theme.black};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    text-decoration: underline;
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.black};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    line-height: 24px;
  }
`;

const ZonesList = styled.div`
  margin-top: 20px;
`;

const ZoneOption = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

const ZoneRect = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: rgba(0, 123, 255, 0.2);
  border: 1px solid #007bff;
`;

const DrawingButton = styled.button`
  margin: 20px;
  height: 40px;
`;

const Shape = styled.div`
  position: absolute;
  border: 2px solid #007bff;
  background-color: rgba(0, 123, 255, 0.5);
  pointer-events: none;
`;

const CaptureButton = styled.button`
  margin: 20px;
  height: 40px;
`;

export const EventCardWrapper = styled.div`
  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
    padding-left: 30px;
    margin-bottom: 7px;
  }
`;

export const EventCard = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.borderColor};
  box-shadow: 3px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
`;

export const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .checkboxField {
    border-radius: 2px;
    opacity: 1;
    padding: 12px 10px;

    label {
      display: flex;
      align-items: center;
      gap: 7px;
      opacity: 1;
      color: rgba(115, 128, 137, 1);
      font-family: "SatoshiRegular";
      font-size: 16px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }

    input[type="checkbox"]:checked + label {
      color: #fff !important;
    }

    input[type="checkbox"] {
      appearance: none;
      background-color: transparent;
      margin: 0;
      font: inherit;
      width: 16px;
      height: 16px;
      border: 1px solid ${(props) => props.theme.green}; /* Green color */
      border-radius: 2px;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      cursor: pointer;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 8px;
      height: 0.6em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 21%, 80% 0, 43% 62%);
      transform: scale(0);
      transition: transform 0.15s ease-in-out;
      background-color: ${(props) =>
    props.theme.green}; /* Green color for the check */
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
  }
`;

export const ParametersTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FootballFielOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 0;
  margin-bottom: 10px;
`;

export const DirectionStatus = styled.div`
  margin-bottom: 10px;

  label {
    display: flex;
    align-items: center;
    gap: 5px;

    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
  }
`;

export const VideoSettingsWrapper = styled.div`
  position: relative;
`;

export const VideoSettings = styled.div`
  border-radius: 1px;
  border: 1px solid rgba(44, 50, 48, 1);
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 196px;
  height: 40px;
  padding: 4px 10px;
  cursor: pointer;

  img {
    transform: rotate(90deg);
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }
`;

export const VideoSettingsContent = styled.div`
  position: absolute;
  border-radius: 2px;
  opacity: 1;
  background-color: ${(props) => props.theme.lines};
  width: 100%;
`;

export const VideoContent = styled.div`
  padding: 20px;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 24px;
    margin-bottom: 10px;
  }

  button {
    border-radius: 1px;
    opacity: 1;
    background-color: ${(props) => props.theme.green};
    width: 100%;
    height: 30px;
    border: none;
    opacity: 1;
    color: rgba(4, 8, 2, 1);
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 20px;
    cursor: pointer;
  }

  label {
    opacity: 1;
    color: rgba(207, 218, 230, 1);
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    display: flex;
    flex-direction: row;
    gap: 10px;

    #specifyColor {
      accent-color: ${(props) => props.theme.green};
    }
  }
`;

export const OffsetFields = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  input {
    border-radius: 1px;
    border: 1px solid rgba(44, 50, 48, 1);
    opacity: 1;
    width: 100%;
    background: transparent;
    height: 40px;
    color: #fff;
    outline: none;

    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiBold";
    font-size: 16px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
  }
`;

export const ZonesWrapper = styled.div`
  border-radius: 1px;
  border: 1px solid rgba(44, 50, 48, 1);
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;
  margin-top: 20px;
  position: relative;

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SatoshiMedium";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    margin: 0;
  }
`;

export const ZoneOpenContent = styled.div`
  background: red;
  position: absolute;
  border: 1px solid rgba(44, 50, 48, 1);
  background: ${(props) => props.theme.lines};
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999;
`;

const EventsMatches = ({ team, paramsList, user }) => {
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const dispatch = useDispatch();
  const { offset_range_begin = 10, offset_range_end = 10 } = useSelector(state => state.user.userProfile);
  const [matchesList, setMatchesList] = useState([]);
  const [displayedMathes, setDisplayedMathes] = useState(
    () => team?.matches_list || []
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [choosenSeasson, setChoosenSeasson] = useState("");
  const [loadingSeason, setLoadingSeason] = useState(false);
  const [competitions, setCompetitions] = useState("");
  const [choosenCompetition, setChoosenCompetition] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [response, setResponse] = useState(null);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [hoveredEvent, setHoveredEvent] = useState(null);

  const [selectedZones, setSelectedZones] = useState([]);
  const [zoneRectangles, setZoneRectangles] = useState({});
  const [showDirection, setShowDirection] = useState(false);
  const [zoneOpen, setZoneOpen] = useState(false);

  // State to manage drawn shapes
  const [drawing, setDrawing] = useState(false);
  const [shapeType, setShapeType] = useState("rectangle"); // Initial shape type (rectangle, line, etc.)
  const [drawnShapes, setDrawnShapes] = useState([]);
  const [previewShape, setPreviewShape] = useState(null);

  // Coordinates tracking
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const startXRef = useRef(0);
  const startYRef = useRef(0);
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [drawingStarted, setDrawingStarted] = useState(false);

  const [offsetBegin, setOffsetBegin] = useState(offset_range_begin);
  const [offsetEnd, setOffsetEnd] = useState(offset_range_end);

  const [open, setOpen] = useState(false);
  const [openCompetition, setOpenCompetition] = useState(false);
  const [openMatchesCount, setOpenMatchesCount] = useState(false);

  const [lastMatches, SetLastMatches] = useState(
    displayedMathes?.length || 0
  );
  const [selectedMatch, setSelectedMatch] = useState("");

  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedCompetition, setSelectedCompetition] = useState("");

  const [videoSettingsOpen, setVideoSettingsOpen] = useState(false);

  const pitchRef = useRef(null);
  const startDrag = useRef(false);

  const [timeLineRange, setTimeLineRange] = useState({ min: 0, max: 90 });

  const getAllZones = useCallback((count) => {
    const currentStep = count > 14 ? count / 3 : count;
    if ([12, 14].includes(count)) {
      return getCustomZones({
        width: fieldWidth,
        height: fieldHeight,
        quantity: count,
        step: currentStep,
      });
    }
    return getZones({
      width: fieldWidth,
      height: fieldHeight,
      quantity: count,
      step: currentStep,
    });
  }, []);

  const matches = useMemo(
    () => [
      {
        id: 5,
        value: 0,
        title: "Deselect all matches",
      },
      {
        id: 1,
        value: displayedMathes?.length || 0,
        title: "All matches",
      },
      {
        id: 2,
        value: 3,
        title: "Last 3 matches",
      },
      {
        id: 3,
        value: 5,
        title: "Last 5 matches",
      },
      {
        id: 4,
        value: 10,
        title: "Last 10 matches",
      },
    ],
    [team]
  );

  const handleSeasonChoose = (item) => {
    setChoosenSeasson(item.id);
    setSelectedSeason(item.years);
    setOpen(false);
    setChoosenCompetition('');
    setSelectedCompetition('');

    setOpenCompetition(false);
  };

  const handleCompetitionChoose = (item) => {
    setChoosenCompetition(prev => prev.includes(item.competition__id) ? prev.filter(oldItem => oldItem !== item.competition__id) : [...prev, item.competition__id]);
    setSelectedCompetition(choosenCompetition?.length > 0 ? `${item.title} + ${choosenCompetition?.length}` : item.title);
  };

  const uniqueCompetitions = useMemo(() => {
    if (competitions?.length > 0) {
      const uniqueArray = getUniqueArrayObjectsByKey(
        competitions,
        "competition__id"
      );
      return uniqueArray;
    }
    return [];
  }, [competitions]);

  const elementRef = useRef(null);

  const captureArea = () => {
    if (elementRef.current) {
      html2canvas(elementRef.current, {
        useCORS: true,
        backgroundColor: null,
      })
        .then((canvas) => {
          const image = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.href = image;
          link.download = "screenshot.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error capturing screenshot:", error);
        });
    }
  };

  function getMousePositionSVG(event, pos) {
    try {
      if (pitchRef?.current) {
        let point = pitchRef?.current?.createSVGPoint();
        if (point) {
          point.x = event.clientX;
          point.y = event.clientY;
          point = point.matrixTransform(
            pitchRef?.current?.getScreenCTM().inverse()
          );
          return point;
        }
        return pos;
      }
      return pos;
    } catch (e) {
      console.log("get mouse position err", e);
      return pos;
    }
  }

  // Event handlers for drawing
  const handleMouseDown = (event) => {
    const isCustomShape = event?.target?.classList?.contains("custom-shape");
    if (isCustomShape) {
      startDrag.current = true;
      event.target.classList.add("draggble");

      const currX = event.target.getAttribute("x");
      const currY = event.target.getAttribute("y");
      startXRef.current = +currX - event.clientX;
      startYRef.current = +currY - event.clientY;
      return;
    }
    if (drawing) {
      // const rect = event.currentTarget.getBoundingClientRect();
      // change method get current coordinates
      const { x, y } = getMousePositionSVG(event, {
        x: event.clientX,
        y: event.clientY,
      });
      setStartX(x);
      setStartY(y);
      startXRef.current = x;
      startYRef.current = y;
      setCurrentX(x);
      setCurrentY(y);
      setDrawingStarted(true);
    }
  };

  const handleMouseMove = (event) => {
    if (startDrag.current) {
      const isExisShapeDraggable = document.querySelector(".draggble");
      if (isExisShapeDraggable) {
        isExisShapeDraggable.setAttribute(
          "x",
          event.clientX + startXRef.current
        );
        isExisShapeDraggable.setAttribute(
          "y",
          event.clientY + startYRef.current
        );
      }
      return;
    }
    if (drawing && drawingStarted) {
      // Check if drawing has started
      // const rect = event.currentTarget.getBoundingClientRect();
      // change method get current coordinates
      const { x, y } = getMousePositionSVG(event, {
        x: event.clientX,
        y: event.clientY,
      });
      const offsetX = x;
      const offsetY = y;
      setCurrentX(offsetX);
      setCurrentY(offsetY);

      const width = Math.abs(offsetX - startX);
      const height = Math.abs(offsetY - startY);
      const minX = Math.min(offsetX, startX);
      const minY = Math.min(offsetY, startY);

      setPreviewShape({
        type: shapeType,
        x: minX,
        y: minY,
        width: shapeType === "rectangle" ? width : 0,
        height: shapeType === "rectangle" ? height : 0,
      });
    }
  };

  const recalculateCountEvents = (newShape) => {
    // Count events inside the new shape
    const count = displayedEvents?.filter((event) => {
      const pos = convertCoordinatesWithPadding(event.coord_x, event.coord_y);
      return (
        pos.x >= newShape.x &&
        pos.x <= newShape.x + newShape.width &&
        pos.y >= newShape.y &&
        pos.y <= newShape.y + newShape.height
      );
    }).length;
    newShape.eventCount = count;
    return newShape;
  };

  const handleMouseUp = (event) => {
    if (startDrag.current) {
      const isExisShapeDraggable = document.querySelector(".draggble");
      if (isExisShapeDraggable) {
        const x = +isExisShapeDraggable.getAttribute("x");
        const y = +isExisShapeDraggable.getAttribute("y");
        const id = isExisShapeDraggable.getAttribute("id");
        if (id) {
          const newShapes = [...drawnShapes];
          const foundedShapeIdx = newShapes.findIndex(
            (item) => item.id === +id
          );
          if (foundedShapeIdx >= 0) {
            const shape = recalculateCountEvents(newShapes[foundedShapeIdx]);
            newShapes[foundedShapeIdx] = {
              ...shape,
              x,
              y,
            };
            setDrawnShapes(newShapes);
          }
          document
            .querySelectorAll(".draggble")
            ?.forEach((e) => e?.classList?.remove("draggble"));
        }
      }
      startDrag.current = false;
      return;
    }
    if (drawing && drawingStarted) {
      // Check if drawing has started
      const width = Math.abs(currentX - startX);
      const height = Math.abs(currentY - startY);
      const minX = Math.min(startX, currentX);
      const minY = Math.min(startY, currentY);

      // Create a new shape
      const newShape = {
        type: shapeType,
        x: minX,
        y: minY,
        width: shapeType === "rectangle" ? width : 0,
        height: shapeType === "rectangle" ? height : 0,
        id: Date.now(),
      };

      const shape = recalculateCountEvents(newShape);
      setDrawnShapes([...drawnShapes, shape]);
      // setDrawing(false);
      setDrawingStarted(false); // Reset drawingStarted
      setPreviewShape(null);
    }
  };

  const renderDrawnShapes = () => {
    return drawnShapes.map((shape, index) => (
      <React.Fragment key={index}>
        {shape.type === "rectangle" && (
          <>
            <rect
              id={shape.id}
              x={shape.x}
              y={shape.y}
              width={shape.width}
              height={shape.height}
              fill="#ffffff29"
              stroke="red"
              strokeWidth="2"
              cursor={"move"}
              className="custom-shape"
            />
          </>
        )}
      </React.Fragment>
    ));
  };

  const isMarkerInRectangle = (marker, rectangle) => {
    const { x, y, width, height } = rectangle;
    const pos = convertCoordinatesWithPadding(marker.coord_x, marker.coord_y);
    return (
      pos.x >= x && pos.x <= x + width && pos.y >= y && pos.y <= y + height
    );
  };

  const [drawMarkers, setDrawMarkers] = useState();
  const logMarkersInDrawnRectangles = () => {
    const markersInRectangles = displayedEvents
      ?.filter((event) =>
        drawnShapes.some(
          (shape) =>
            shape.type === "rectangle" && isMarkerInRectangle(event, shape)
        )
      )
      .map((event) => event.id); // Pretvorite markere u ID-ove

    setDrawMarkers(markersInRectangles);
  };

  useEffect(() => {
    logMarkersInDrawnRectangles();
  }, [drawnShapes, response]);

  const renderPreviewShape = () => {
    if (previewShape && previewShape.type === "rectangle") {
      return (
        <rect
          x={previewShape.x}
          y={previewShape.y}
          width={previewShape.width}
          height={previewShape.height}
          fill="transparent"
          stroke="blue"
          strokeWidth="2"
          strokeDasharray="4"
        />
      );
    }
    return null;
  };

  // Zones
  const handleZoneSelection = (zoneNumber) => {
    // If the selected zone is already chosen, uncheck it and clear rectangles
    if (selectedZones.includes(zoneNumber)) {
      setSelectedZones([]);
      setZoneRectangles((prevRectangles) => ({
        ...prevRectangles,
        [zoneNumber]: prevRectangles[zoneNumber].map((rect) => ({
          ...rect,
          visible: false,
        })),
      }));
      return;
    }

    // Clear previous zone selection
    setSelectedZones([zoneNumber]);

    // Create rectangles for the newly selected zone
    // const zoneWidth = (fieldWidth - 2 * fieldPadding) / zoneNumber; // Width per zone
    // const rectangles = Array.from({ length: zoneNumber }, (_, index) => ({
    //   x: index * zoneWidth + fieldPadding,
    //   y: fieldPadding,
    //   width: zoneWidth,
    //   height: fieldHeight,
    //   visible: true,
    // }));

    setZoneRectangles((prevRectangles) => ({
      ...prevRectangles,
      [zoneNumber]: getAllZones(zoneNumber),
    }));
  };

  const countEventsInRectangles = (rectangles, events) => {
    return rectangles.map((rect) => {
      const count = events?.filter((event) => {
        const pos = convertCoordinatesWithPadding(event.coord_x, event.coord_y);
        return (
          pos.x >= rect.x &&
          pos.x <= rect.x + rect.width &&
          pos.y >= rect.y &&
          pos.y <= rect.y + rect.height
        );
      }).length;
      return { ...rect, eventCount: count };
    });
  };

  const [allMarkerIds, setAllMarkerIds] = useState(new Set());
  const [zoneMarkers, setZoneMarkers] = useState();

  const handleRemoveRectangle = (zoneNumber, index) => {
    const updatedRectangles = { ...zoneRectangles };
    const rectangle = updatedRectangles[zoneNumber][index];
    rectangle.visible = !rectangle.visible;

    if (!rectangle.visible) {
      const markersInRectangle = displayedEvents?.filter((event) => {
        const pos = convertCoordinatesWithPadding(event.coord_x, event.coord_y);
        return (
          pos.x >= rectangle.x &&
          pos.x <= rectangle.x + rectangle.width &&
          pos.y >= rectangle.y &&
          pos.y <= rectangle.y + rectangle.height
        );
      });

      //  console.log(
      //    `Markers in rectangle ${index} of zone ${zoneNumber}:`,
      //    markersInRectangle
      //  );

      // Update allMarkerIds with new markers and maintain unique IDs
      setAllMarkerIds((prevIds) => {
        const updatedIds = new Set(prevIds);

        // Add new marker IDs
        markersInRectangle?.forEach((marker) => {
          updatedIds.add(marker.id);
        });

        // Convert Set to array if necessary
        const uniqueMarkerIdsArray = Array.from(updatedIds);
        setZoneMarkers(uniqueMarkerIdsArray);

        // Log the updated list of unique marker IDs
        //  console.log(
        //    "All unique marker IDs from clicked rectangles:",
        //    uniqueMarkerIdsArray
        //  );

        return updatedIds;
      });
    }

    setZoneRectangles(updatedRectangles);
  };

  const renderZones = () => {
    return selectedZones.map((zoneNumber) =>
      countEventsInRectangles(
        zoneRectangles[zoneNumber] || [],
        displayedEvents || []
      ).map((rectangle, index) => (
        <g
          key={`zone-${zoneNumber}-${index}`}
          className={`zone-${rectangle?.id || index}`}
          onClick={() => handleRemoveRectangle(zoneNumber, index)}
        >
          <rect
            style={{
              opacity: rectangle.visible ? 1 : 0.5,
            }}
            x={rectangle.x}
            y={rectangle.y}
            width={rectangle.width}
            height={rectangle.height}
            fill="rgba(0, 123, 255, 0.2)"
            stroke="#fff"
            strokeWidth={2}
          />
          <text
            x={rectangle.x + rectangle.width / 2}
            y={rectangle.y + rectangle.height / 2}
            textAnchor="middle"
            alignmentBaseline="central"
            fill="#000"
            fontSize="12"
          ></text>
          <circle
            cx={rectangle.x + rectangle.width / 2}
            cy={rectangle.y + rectangle.height / 2}
            r="30"
            style={{
              strokeWidth: 1,
              stroke: "white",
              fill: "rgba(104, 134, 189, 0.68)",
            }}
          />
        </g>
      ))
    );
  };

  const toggleDrawingMode = () => {
    setDrawing((prev) => !prev);
    if (drawing) {
      setPreviewShape(null);
    }
    setDrawnShapes([]);
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const handleSelectionChange = (ids) => {
    setMatchesList((prev) =>
      Array.isArray(ids)
        ? [...new Set([...prev, ...ids])]
        : prev.includes(ids)
          ? prev.filter((item) => item !== ids)
          : [...prev, ids]
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSeasons = async () => {
    try {
      setChoosenCompetition("");
      setMatchesList([]);
      setLoadingSeason(true);
      const response = await $apiV1.get(
        `/statistics/team/${id}?year_season_id=${choosenSeasson}`
      );

      // const filteredMatches = team.matches_list
      //   .filter((match) => match.competition_id === choosenCompetition)
      //   .map((match) => match.id);

      setDisplayedMathes(response.data?.matches_list);
      setCompetitions(response.data.matches_played);
      SetLastMatches(response.data?.matches_list?.length || 0);
      const uniqueArrayComp = getUniqueArrayObjectsByKey(
        response.data.matches_played,
        "competition__id"
      );
      const years = response?.data?.available_seasons?.find(item => item.id === +choosenSeasson)
      setChoosenSeasson(choosenSeasson || response?.data?.available_seasons?.[0].id || '');
      setSelectedSeason(years?.years || response?.data?.available_seasons?.[0].years || '');
      setChoosenCompetition(uniqueArrayComp?.map(item => item.competition__id));
      // setMatchesList(filteredMatches);

      // Filter matches to show based on the updated list
      // const matchesToShow = response.data.matches_list.filter((match) =>
      //   filteredMatches.includes(match.id)
      // );
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoadingSeason(false);
    }
  };

  useEffect(() => {
    handleSeasons();
  }, [choosenSeasson]);

  useEffect(() => {
    if (choosenCompetition?.length > 0) {
      const filteredMatches = displayedMathes
        ?.filter((match) => choosenCompetition.includes(+match.competition_id))
        ?.slice(0, lastMatches)
        ?.map((match) => match.id);
      setMatchesList(filteredMatches);
    } else {
      const filteredMatches = displayedMathes
        ?.slice(0, lastMatches)
        ?.map((match) => match.id);
      setMatchesList(filteredMatches);
    }
  }, [choosenCompetition, lastMatches, displayedMathes]);

  const handleCheckboxChange = (id) => {
    setCheckedIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((item) => item !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (matchesList.length > 0 && checkedIds.length > 0) {
      const fetchData = async () => {
        try {
          const response = await $apiV1.get(`/stats/team/${id}`, {
            params: {
              matches: matchesList.join(","),
              params: checkedIds.join(","),
            },
          });

          const allMarkers = response.data.stats.flatMap((stat) =>
            stat.parameters.flatMap((param) => param.markers)
          );

          setMarkers(allMarkers);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    } else {
      console.log('reset markers')
      setMarkers([]);
      setDisplayedEvents([]);

    }
  }, [matchesList, checkedIds]);

  useEffect(() => {
    if (markers.length > 0) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const result = await $apiV1.post("/stats/events_search/", {
            markers: markers,
          });

          setResponse(result.data);
          setDisplayedEvents(result.data.events);
          const max = findMaxSecondMarker(result.data.events);
          setTimeLineRange({ min: 0, max: Math.ceil(+max / 60 / 5) * 5 });
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [markers]);

  const fieldWidth = 649; // Field width in pixels
  const fieldHeight = 492; // Field height in pixels

  const convertCoordinates = (x, y) => {
    const scaleX = fieldWidth / 100; // Adjust the scale factor as needed
    const scaleY = fieldHeight / 100;
    return {
      x: x * scaleX,
      y: fieldHeight - y * scaleY, // Invert the y-coordinate
    };
  };
  const fieldPadding = 5;

  const convertCoordinatesWithPadding = (x, y) => {
    const scaleX = (fieldWidth - 2 * fieldPadding) / 104; // Adjust the scale factor to account for padding
    const scaleY = (fieldHeight - 2 * fieldPadding) / 70;
    return {
      x: fieldPadding + x * scaleX,
      y: fieldHeight - fieldPadding - y * scaleY, // Invert the y-coordinate and adjust for padding
    };
  };

  const getTotalEventsInUnselectedRectangles = () => {
    if (selectedZones.length === 0 || displayedEvents?.length === 0) {
      return 0;
    }

    // Filter to get rectangles in selected zones
    const rectanglesInSelectedZones = selectedZones.flatMap(
      (zoneNumber) => zoneRectangles[zoneNumber] || []
    );

    // Count events in unselected rectangles
    const rectanglesWithCounts = countEventsInRectangles(
      rectanglesInSelectedZones,
      displayedEvents || []
    );

    return rectanglesWithCounts
      .filter((rect) => !rect.visible) // Consider only unselected rectangles
      .reduce((total, rect) => total + rect.eventCount, 0);
  };

  const eventsCount =
    selectedZones.length > 0
      ? getTotalEventsInUnselectedRectangles()
      : displayedEvents?.length || 0;

  const isDotInsideRectangle = (dot, rectangle) => {
    const pos = convertCoordinatesWithPadding(dot.coord_x, dot.coord_y);
    return (
      pos.x >= rectangle.x &&
      pos.x <= rectangle.x + rectangle.width &&
      pos.y >= rectangle.y &&
      pos.y <= rectangle.y + rectangle.height
    );
  };

  const isDotInsideAnyRectangle = (dot, rectangles) => {
    return rectangles.some((rectangle) => isDotInsideRectangle(dot, rectangle));
  };

  // Offset
  const handleOffset = async () => {
    try {
      await $apiV1.patch(`/users/profile_settings/${user}/`, {
        params: {
          offset_range_begin: offsetBegin,
          offset_range_end: offsetEnd,
        },
      });
      dispatch(userActions.changeOffsetBegin(offsetBegin));
      dispatch(userActions.changeOffsetEnd(offsetEnd));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMatches = (item) => {
    SetLastMatches(item.value);
    setSelectedMatch(item.title);
    setOpenMatchesCount(false);
  };

  const handleChangeTimelineRange = ({ min, max }) => {
    const newDisplayedEvents = response?.events?.filter(
      (item) => +item.second >= min * 60 && +item.second <= max * 60
    );
    setDisplayedEvents(newDisplayedEvents);
  };

  const debouncedChangeTimeLineRange = debounce(handleChangeTimelineRange, 500);

  const resetAllSettings = () => {
    setDisplayedEvents([]);
    setChoosenCompetition([]);
    SetLastMatches(0);
    setSelectedMatch('');
    setSelectedSeason("");
    setOffsetBegin("");
    setOffsetEnd("");
    setShowDirection(false);
    setSelectedZones([]);
    setZoneRectangles([]);
    setCheckedIds([]);
    setZoneMarkers([]);
    setDisplayedEvents([]);
    setMarkers([]);
    setResponse(null)
  }

  const seasonDropdownRef = useOutsideClick(() => {
    if (open) {
      setOpen(false);
    }
  });

  const competitionDropdownRef = useOutsideClick(() => {
    if (openCompetition) {
      setOpenCompetition(false);
    }
  });

  const mathesCountDropdownRef = useOutsideClick(() => {
    if (openMatchesCount) {
      setOpenMatchesCount(false);
    }
  });

  const dropdownRef = useOutsideClick(() => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  return (
    <>
      <EventsMatchesWrap>
        <ListOfMatches>
          <EventCardWrapper>
            <h3>Choose Season & Match Parameters</h3>
            <EventCard>
              <EventContent>
                <DropdownWrapper ref={seasonDropdownRef} disabled={loadingSeason}>
                  <div
                    style={{ position: "relative" }}
                    onClick={() => setOpen(!open)}
                  >
                    <TextInput
                      type="text"
                      value={
                        selectedSeason.length == 0
                          ? "Select Season"
                          : selectedSeason
                      }
                      readOnly
                    />

                    <img
                      src={open ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {open && (
                    <OpenedDropdown>
                      {team.available_seasons?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => handleSeasonChoose(item)}
                        >
                          {item.years}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>

                <Dropdown ref={competitionDropdownRef} disabled={loadingSeason}>
                  <DropdownButton
                    onClick={() => setOpenCompetition(!openCompetition)}
                  >
                    {choosenCompetition.length === 0
                      ? "Select Competition"
                      : `${choosenCompetition.length} selected`}
                    <img src={openCompetition ? arrowIconUp : arrowIconDown} />
                  </DropdownButton>
                  <DropdownContent show={openCompetition}>
                    {uniqueCompetitions?.map((item) => (
                      <DropdownItem
                        key={item?.competition__id}
                        selected={choosenCompetition.includes(item.competition__id)}
                        onClick={() => handleCompetitionChoose(item)}
                      >
                        {item?.title}
                      </DropdownItem>
                    ))}
                  </DropdownContent>
                </Dropdown>

                <DropdownWrapper ref={mathesCountDropdownRef} disabled={loadingSeason}>
                  <div
                    style={{ position: "relative" }}
                    onClick={() => setOpenMatchesCount(!openMatchesCount)}
                  >
                    <TextInput
                      type="text"
                      value={
                        selectedMatch.length == 0 ? "PRE-SET" : selectedMatch
                      }
                      readOnly
                    />

                    <img
                      src={openMatchesCount ? arrowIconUp : arrowIconDown}
                      alt={arrowIconDown}
                      style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  {openMatchesCount && (
                    <OpenedDropdown>
                      {matches?.map((item, index) => (
                        <div key={index} onClick={() => handleMatches(item)}>
                          {item.title}
                        </div>
                      ))}
                    </OpenedDropdown>
                  )}
                </DropdownWrapper>

                <Dropdown ref={dropdownRef} disabled={loadingSeason}>
                  <DropdownButton
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    {matchesList.length === 0
                      ? "Select Matches"
                      : `${matchesList.length} selected`}
                    <img src={showDropdown ? arrowIconUp : arrowIconDown} />
                  </DropdownButton>
                  <DropdownContent show={showDropdown}>
                    {displayedMathes?.map((item, index) => (
                      <DropdownItem
                        key={index}
                        selected={matchesList.includes(item.id)}
                        onClick={() => handleSelectionChange(item.id)}
                      >
                        {item.home_team} {item.score} {item.away_team}{" "}
                        {item.competition}
                      </DropdownItem>
                    ))}
                  </DropdownContent>
                </Dropdown>
              </EventContent>
            </EventCard>
          </EventCardWrapper>

          <EventCardWrapper>
            <h3>Choose Other Parameters</h3>
            <EventCard style={{ maxHeight: "400px", overflow: "scroll" }}>
              <EventContent>
                <TabsWrapper>
                  <TopList
                    needBackGroundColor={true}
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <TabListWrapper
                      style={{
                        overflow: "auto",
                        scrollbarWidth: "auto",
                        overflowY: "hidden",
                      }}
                    >
                      {paramsList?.map((item) => {
                        return <TabTitle key={item.id}>{item.title}</TabTitle>;
                      })}
                    </TabListWrapper>
                  </TopList>
                  {paramsList?.map((item) => {
                    return (
                      <TabContent key={item.id}>
                        <ParametersTabWrapper>
                          {item?.actions?.map((action, index) => (
                            <div className="checkboxField" key={action.id}>
                              <label htmlFor={action.id}>
                                <input
                                  id={action.id}
                                  type="checkbox"
                                  checked={checkedIds.includes(action.id)}
                                  onChange={() =>
                                    handleCheckboxChange(action.id)
                                  }
                                />
                                {action.title}
                              </label>
                            </div>
                          ))}
                        </ParametersTabWrapper>
                      </TabContent>
                    );
                  })}
                </TabsWrapper>
                {/* {paramsList.map((item, index) => (
                  <div className="checkboxField" key={index}>
                    <label htmlFor={item.id}>
                      <input
                        id={item.id}
                        type="checkbox"
                        checked={checkedIds.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                      {item.name}
                    </label>
                  </div>
                ))} */}
              </EventContent>
            </EventCard>
          </EventCardWrapper>
        </ListOfMatches>

        {loading ? (
          <LoadingState style={{ margin: "20px" }}>
            <Oval
              visible={true}
              height="80"
              width="80"
              secondaryColor={
                localStorage.getItem("theme") === "light"
                  ? "#4E5255"
                  : "#CFDAE6"
              }
              color={
                localStorage.getItem("theme") === "light"
                  ? "#2363F7"
                  : "#4fa94d"
              }
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </LoadingState>
        ) : (
          <FootballField
            ref={elementRef}
            onMouseDown={drawing ? handleMouseDown : null}
            onMouseMove={drawing ? handleMouseMove : null}
            onMouseUp={drawing ? handleMouseUp : null}
          >
            {/* <ZonesList>
            {[3, 6, 12].map((zone) => (
              <ZoneOption key={zone}>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedZones.includes(zone)}
                    onChange={() => handleZoneSelection(zone)}
                  />
                  Zone {zone}
                </label>
              </ZoneOption>
            ))}
          </ZonesList> */}
            {/* <button
            onClick={() => {
              if (drawMarkers?.length > 0) {
                navigate(`/tournaments/match/video?markers=${drawMarkers}`);
              } else {
                const markersToShow =
                  selectedZones.length > 0 ? zoneMarkers : markers.join(",");
                navigate(`/tournaments/match/video?markers=${markersToShow}`);
              }
            }}
          >
            Watch {drawMarkers?.length > 0 ? drawMarkers?.length : eventsCount}{" "}
            events
          </button> */}

            {/* <label>
            <input
              type="checkbox"
              checked={showDirection}
              onChange={() => setShowDirection(!showDirection)}
            />
            Show Direction
          </label> */}

            {/* <DrawingButton onClick={toggleDrawingMode}>
            {drawing ? "Disable Drawing" : "Enable Drawing"}
          </DrawingButton> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={resetAllSettings}
                title={"Reset all"}
                size={"normal"}
                style={{ margin: "0 20px 20px 5px" }}
              />

              <Button
                onClick={toggleDrawingMode}
                title={drawing ? "Disable Drawing" : "Enable Drawing"}
                size={"normal"}
                style={{ margin: "0 5px 20px 20px" }}
              />
            </div>


            <FootballFielOptions>
              <VideoSettingsWrapper>
                <VideoSettings
                  onClick={() => setVideoSettingsOpen(!videoSettingsOpen)}
                >
                  <h3>Video Settings</h3>
                  <img src={arrowIconUp} />
                </VideoSettings>
                {videoSettingsOpen && (
                  <VideoSettingsContent>
                    <VideoContent>
                      <h3>Offset</h3>
                      <OffsetFields>
                        <input
                          type="number"
                          value={offsetBegin}
                          onChange={(e) => setOffsetBegin(e.target.value)}
                        />
                        <input
                          type="number"
                          value={offsetEnd}
                          onChange={(e) => setOffsetEnd(e.target.value)}
                        />
                      </OffsetFields>
                      <button onClick={() => handleOffset()}>add offset</button>

                      <h3 style={{ marginTop: "20px" }}>
                        Football Pitch Settings
                      </h3>
                      <label>
                        <input
                          type="checkbox"
                          id="specifyColor"
                          checked={showDirection}
                          onChange={() => setShowDirection(!showDirection)}
                        />
                        Show Direction
                      </label>

                      <ZonesWrapper onClick={() => setZoneOpen(!zoneOpen)}>
                        <h3>Zones</h3>
                        <img src={zoneOpen ? arrowIconUp : arrowIconDown} />

                        {zoneOpen && (
                          <ZoneOpenContent>
                            {[3, 6, 12, 14, 18].map((zone) => (
                              <ZoneOption key={zone}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedZones.includes(zone)}
                                    onChange={() => handleZoneSelection(zone)}
                                  />
                                  Zone {zone}
                                </label>
                              </ZoneOption>
                            ))}
                          </ZoneOpenContent>
                        )}
                      </ZonesWrapper>
                    </VideoContent>
                  </VideoSettingsContent>
                )}
              </VideoSettingsWrapper>
              <Button
                disabled={eventsCount === 0}
                title={`Watch ${drawMarkers?.length > 0 ? drawMarkers?.length : eventsCount
                  }
            events`}
                onClick={() => {
                  if (drawMarkers?.length > 0) {
                    window.open(
                      `/tournaments/match/video?title=Events - ${team?.team?.name}&markers=${drawMarkers}`,
                      "_blank"
                    );
                  } else {
                    const markersToShow =
                      selectedZones.length > 0
                        ? zoneMarkers
                        : displayedEvents?.map((item) => item.id)?.join(",");
                    window.open(
                      `/tournaments/match/video?title=Events - ${team?.team?.name}&markers=${markersToShow}`,
                      "_blank"
                    );
                  }
                }}
                onContextMenu={() => {
                  if (drawMarkers?.length > 0) {
                    window.open(
                      `/tournaments/match/video?title=Events - ${team?.team?.name}&markers=${drawMarkers}`,
                      "_blank"
                    );
                  } else {
                    const markersToShow =
                      selectedZones.length > 0
                        ? zoneMarkers
                        : displayedEvents?.map((item) => item.id)?.join(",");
                    window.open(
                      `/tournaments/match/video?title=Events - ${team?.team?.name}&markers=${markersToShow}`,
                      "_blank"
                    );
                  }
                }}
              />
            </FootballFielOptions>

            <svg
              ref={pitchRef}
              width={fieldWidth + 2 * fieldPadding}
              height={fieldHeight + 2 * fieldPadding}
              viewBox={`0 0 ${fieldWidth + 2 * fieldPadding} ${fieldHeight + 2 * fieldPadding
                }`}
              preserveAspectRatio="xMidYMid meet"
              style={{
                border: "1px solid black",
                cursor: drawing ? "crosshair" : "auto",
              }}
            >
              {/* Background Image */}
              <image
                href={backgroundImage}
                x={fieldPadding}
                y={fieldPadding}
                width={fieldWidth}
                height={fieldHeight}
                preserveAspectRatio="xMidYMid slice"
              />
              {renderZones()}
              {renderDrawnShapes()}
              {renderPreviewShape()}

              {/* Render the field layout here */}
              {displayedEvents?.map((event) => {
                const {
                  coord_x,
                  coord_y,
                  coord_x_destination,
                  coord_y_destination,
                } = event;
                const position = convertCoordinatesWithPadding(
                  coord_x,
                  coord_y
                );
                const destinationPosition =
                  coord_x_destination != null && coord_y_destination != null
                    ? convertCoordinatesWithPadding(
                      coord_x_destination,
                      coord_y_destination
                    )
                    : null;
                return (
                  <React.Fragment key={event.id}>
                    {showDirection && destinationPosition && (
                      <line
                        x1={position.x}
                        y1={position.y}
                        x2={destinationPosition.x}
                        y2={destinationPosition.y}
                        stroke="#BFE82D"
                        strokeWidth="2"
                        strokeDasharray="0"
                      />
                    )}
                    <a
                      href={`/tournaments/match/video?title=${event.action} - ${event?.creator?.display_name}&markers=${event.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <circle
                        cx={position.x}
                        cy={position.y}
                        r="5"
                        fill={
                          isDotInsideAnyRectangle(event, drawnShapes)
                            ? "red"
                            : "#040802"
                        }
                        onMouseEnter={() => setHoveredEvent(event)}
                        onMouseLeave={() => setHoveredEvent(null)}
                      // onClick={() =>
                      //   navigate(
                      //     `/tournaments/match/video?markers=${event.id}`
                      //   )
                      // }
                      />
                    </a>
                  </React.Fragment>
                );
              })}
            </svg>

            {/* Info Box */}
            <InfoBox
              visible={!!hoveredEvent}
              top={
                hoveredEvent
                  ? convertCoordinates(
                    hoveredEvent.coord_x,
                    hoveredEvent.coord_y
                  ).y
                  : 0
              }
              left={
                hoveredEvent
                  ? convertCoordinates(
                    hoveredEvent.coord_x,
                    hoveredEvent.coord_y
                  ).x
                  : 0
              }
            >
              {hoveredEvent ? (
                <>
                  <img
                    src={hoveredEvent.creator.photo}
                    alt={hoveredEvent.creator.photo}
                  />
                  <h4>{hoveredEvent.creator.display_name}</h4>
                  <p>
                    {hoveredEvent.match.home_team.name}{" "}
                    {hoveredEvent.match.away_team.name},{" "}
                    {hoveredEvent.match.competition.name}
                  </p>
                  <h5>{hoveredEvent.action}</h5>
                  {hoveredEvent.recipient && (
                    <p>To {hoveredEvent.recipient.display_name}</p>
                  )}
                </>
              ) : (
                <p>No player info available</p>
              )}
            </InfoBox>

            <TimelineChange
              onChange={debouncedChangeTimeLineRange}
              min={timeLineRange?.min}
              max={timeLineRange?.max}
              styleContainer={{ marginTop: 35 }}
            />
          </FootballField>
        )}
      </EventsMatchesWrap>
    </>
  );
};

export default EventsMatches;

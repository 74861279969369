import React, { useEffect, useState } from 'react'
import useOutsideClick from '../../../hooks/useOutsideClick';
import { DropdownWrapper, OpenedDropdown } from './SelectComponents';
import arrowIconUp from "../../../images/up.svg";
import arrowIconDown from "../../../images/down.svg";

export const Select = (props) => {
    const {
        defaultValue = 'Select value', // default displayed value
        data = [], // data should be in format [{id: number, title: string, ...other params}, ...other data]
        handleClickItem, // for handle api requests or something else
        styleInput = {},
    } = props;
    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        setSelectedValue(defaultValue);
    }, [defaultValue])

    // for close if was click outside component
    const dropdownRef = useOutsideClick(() => {
        if (openDropdown) {
            setOpenDropdown(false);
        }
    });

    const onSelectValue = (item) => {
        setSelectedValue(item?.title);
        handleClickItem?.(item);
        setOpenDropdown(false);
    }

    return (
        <DropdownWrapper ref={dropdownRef}>
            <div
                style={{ position: "relative", display: 'flex', alignItems: 'center' }}
                onClick={() => setOpenDropdown(prev => !prev)}
                title={selectedValue}
            >
                <input
                    style={{ ...styleInput }}
                    type={'text'}
                    value={selectedValue}
                    disabled={data?.length === 0}
                    readOnly
                />

                <img
                    src={openDropdown ? arrowIconUp : arrowIconDown}
                    alt={arrowIconDown}
                    style={{
                        color: "#fff",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                    }}
                />
            </div>

            {openDropdown && (
                <OpenedDropdown>
                    {data?.map((item) => (
                        <div
                            key={item.id}
                            onClick={() => onSelectValue(item)}
                            title={item.title}
                        >
                            {item.title}
                        </div>
                    ))}
                </OpenedDropdown>
            )}
        </DropdownWrapper>
    )
}
